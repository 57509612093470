@layer reset, global, app;
@import "reset.scss" layer(reset);

@layer global {
	:root {
		--ico: red;
	}
	* {
		margin: 0;
		padding: 0;
		font-size: inherit;
		font-family: inherit;
	}

	body {
		--border-radius: 1em;
		--main-color: #90f;
		--background-color: #d3b0e8;
		--secondary-color: #571782;
		--secondary-button-color: #d3b0e8;
		--default-button-color: #333333;
		--dark-color: #5e4370;
		--light-contrast-color: #fff;
		--contrast-color: #6f0;
		--contrast-on-black-color: #fff;
	}

	html,
	body {
		overflow-x: hidden;
		position: relative; /* needed for "overflow: hidden;" to work on safari */
		scroll-behavior: smooth;
	}

	body {
		font-family: "M PLUS Rounded 1c", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 2em;

		background-color: var(--background-color, #d3b0e8);

		display: flex;
		justify-content: center;
		align-items: center;

		min-height: 100vh;
		padding-top: 3.5em;
		padding-bottom: 0.25em;

		transition: padding 0.4s;

		@media (width > 640px) {
			padding-top: 0.25em;
		}
	}

	::selection {
		color: #000;
		background-color: var(--contrast-on-black-color);
	}

	:focus {
		outline: none;
	}

	:focus-visible {
		outline: 0.17rem solid var(--contrast-color);
		outline-offset: 0.13rem;
		transition: outline-color 0.4s;
	}
	button:focus-visible {
		outline-width: 0.21rem;
		outline-offset: 0.17rem;
	}
	/* button,
	input {
		position: relative;
	}
	:focus-visible::before {
		content: "";
		position: absolute;
		inset: -0.3rem;
		border: 0.13rem solid var(--contrast-color);
		border-radius: inherit;
	}
	button:focus-visible::before {
		inset: -0.37rem;
		border-width: 0.21rem;
		border-radius: inherit;
	} */
}
