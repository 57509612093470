._ {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	max-width: 6.4em;

	padding: 0.7em 0.7em;

	div {
		display: flex;
		align-items: center;
	}

	button,
	span {
		font-size: 0.6em;
		color: var(--dark-color, #5e4370);
		padding: 0 0.2em;

		transition: color 0.6s;
	}
	button {
		background: none;
		border: none;
		border-radius: 2em;

		padding: 0.4em 0.2em;
		cursor: pointer;

		&:focus-visible {
			outline-width: 0.17em;
			outline-offset: 0.1em;
		}
	}

	input {
		width: 1em;
		height: 1em;
		margin: 0 0.2em;
		appearance: none;
		border: 1px solid var(--dark-color, #5e4370);
		border-radius: 0;
		cursor: pointer;

		transition: border-color 0.3s;

		&.round {
			border-radius: 50%;
		}

		&:checked {
			background-color: var(--secondary-color, #571782);
		}

		&:focus-visible {
			outline-offset: 0.07em;
		}

		&[type="color"] {
			border-radius: 50%;

			&::-webkit-color-swatch-wrapper {
				padding: 0;
				border-radius: 50%;
			}

			&::-moz-color-swatch {
				border: none;
				border-radius: 50%;
			}
			&::-webkit-color-swatch {
				border: none;
				border-radius: 50%;
			}
		}
	}
}
