@layer app {
	#root {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	header {
		z-index: 3;

		h1 {
			padding: 0.2em 0.8em;
			margin-bottom: 0.4em;
			border-radius: calc(2 * var(--border-radius));
			background-color: #000;

			color: #fff;
			font-size: 1.5em;
			font-weight: bold;
			text-align: center;

			transition: border-radius 0.6s;
		}
	}

	main {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
