._ {
	position: relative;
	height: 5.5em;
	padding: 0 1.3em; // To be reactive on hover on the side => access to reset button

	color: var(--dark-color);
	transition: color 0.6s;

	div {
		height: 100%;
		display: flex;
		align-items: flex-end;

		mask-image: linear-gradient(#0000, #000f);

		ul {
			max-height: 5.5em;
			overflow: auto;
			line-height: 1.3;

			list-style: none;
			padding-bottom: 0.3em;
			text-align: end;

			scroll-behavior: smooth;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}

			li {
				font-size: 0.7em;
			}
		}
	}

	// "X" button to clear history
	button {
		display: none;
		position: absolute;
		top: 0;
		right: 0;

		height: 1.2em;
		width: 1.2em;
		font-size: 0.7em;
		line-height: 0;
		padding-bottom: 0.17em;
		justify-content: center;
		align-items: center;

		color: var(--dark-color);
		border: 1px solid var(--dark-color-fade);
		border-radius: 2em;
		background: none;

		transition: scale 0.2s, background 0.2s, border-color 0.2s, color 0.2s;

		&:hover {
			transform-origin: center;
			scale: 1.1;
			background: #000;
			border-color: #000;
			color: #fff;
			cursor: pointer;
		}

		// Tooltip description
		&::after {
			content: "Clear history";
			position: absolute;
			top: 2em;
			padding: 1em;
			border-radius: 1em;

			text-align: center;
			white-space: nowrap;
			font-size: 0.7em;

			background-color: var(--dark-color);
			color: #fff;

			visibility: hidden;
			opacity: 0;
		}
		// "^" on top of the tooltip
		&::before {
			content: "";
			position: absolute;
			top: 1.2em;
			width: 0;
			height: 0;

			border: 1px solid transparent;
			border-width: 0 0.25em 0.25em 0.25em;

			border-bottom-color: var(--dark-color);

			visibility: hidden;
			opacity: 0;
		}

		&:hover::after,
		&:hover::before {
			visibility: visible;
			opacity: 1;
			transition: opacity 0.3s 0.8s, visibility 0s 0.8s;
		}
	}

	&:hover button {
		display: flex;
	}
}
