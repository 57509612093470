.alertModal {
	--main-modal-color: hsl(280, 80%, 40%);
	--main-icon-color: hsl(280, 30%, 70%);
	--red-icon-color: hsl(345, 70%, 45%);

	position: absolute;
	inset: 0;
	z-index: 4;

	display: grid;
	place-content: center;
	text-align: center;

	background-color: #222d;

	font-size: 0.8em;

	div {
		background-color: #ddd;
		border-radius: 2em;
		padding: 1em;

		position: relative;
		z-index: 1;
	}

	h2 {
		font-size: 1.2em;
	}

	p {
		margin: 0.5em auto 1em auto;
		font-size: 0.7em;
		max-width: 51ch;
		text-align: justify;
		text-align-last: center;
	}

	button,
	a {
		margin: 0.1em auto;

		color: var(--main-modal-color);
		border-color: var(--main-modal-color);
		cursor: pointer;

		transition: scale 0.3s;
		&:hover {
			scale: 1.03;
		}
	}
}

.primary {
	display: inline-block;
	text-decoration: none;
	position: relative;
	background-color: #fff;
	border: 1px solid #222;
	border-radius: 1em;
	padding: 0.1em 0.7em;

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: 1em;
		box-shadow: 0 0 0.3em var(--main-modal-color);

		opacity: -1;
		transition: opacity 0.4s;
	}

	&:hover::before {
		opacity: 1;
	}
}

.secondary {
	display: block;
	background: none;
	border: none;
	font-size: 0.7em;

	border-bottom: 1px solid #222;
}

.svg {
	max-width: 4em;
	transition: rotate 1s;
	&:hover {
		rotate: 720deg;
		transition: rotate 7s;

		& > path {
			rotate: -720deg;
			transition: rotate 7s;
		}
	}

	& > path {
		fill: var(--red-icon-color);
		transform-origin: center;
		transition: rotate 1s;
	}

	g path {
		fill: var(--main-icon-color);
	}
	g path:nth-child(1),
	g path:nth-child(2) {
		fill: none;
		stroke: var(--main-icon-color);
		stroke-width: 13;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}
}

// "!" icon displayed on app to notified the problem when modal is closed :
.alertIcon {
	position: fixed;
	bottom: 0.5em;
	right: 0.5em;
	width: 2em;
	height: 2em;

	font-size: 0.7em;
	color: red;
	text-align: center;

	border: 1px solid red;
	border-radius: 1em;
	background: #fff5;
	box-shadow: 0 0 0.8em 0.2em #fff5;
	cursor: pointer;
}
