.container {
	button {
		background-color: var(--default-button-color, #333);
		color: #fff;
		width: 2em;
		height: 2em;
		border: 0;
		border-radius: calc(3 * var(--border-radius));
		padding: 0.75em;

		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 0;
		cursor: pointer;

		transition: border-radius 0.6s;
	}

	.operation {
		background-color: #90f;
		background-color: var(--main-color, #90f);
		color: var(--light-contrast-color, #eee);
		font-size: 1.2em;
		width: calc(2em / 1.2);
		height: calc(2em / 1.2);
		align-items: inherit;

		transition: color 0.6s, border-radius 0.6s;
	}

	.secondary {
		background-color: var(--secondary-button-color, #aaa);
		color: #000;
		font-size: 0.8em;
		width: calc(2em / 0.8);
		height: calc(2em / 0.8);
	}

	&.wide {
		grid-column: span 2;

		button {
			width: 4.5em;
			justify-content: flex-start;
		}
	}
}
